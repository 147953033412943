import React from "react"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import StyledIllustration from "src/components/features/StyledIllustration.jsx"
import TestimonialCard from "src/components/TestimonialCard"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"

import eventsIllustration from "src/assets/img/icons/undraw/events.svg"
import creditCardIllustration from "src/assets/img/icons/undraw/credit-card-gradient.svg"
import dominicAvatar from "src/assets/img/dominic-medium600.jpg"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Online booking"
        pathname="/features/online-booking"
        description="Carebit includes fast online booking and payment for patients. Learn more about this feature here."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Online booking</h1>
              <p className="u-whiteTranslucent">
                In this connected world and as a discerning healthcare customer,
                patients expect to be able to arrange their affairs whenever it
                suits them. Carebit allows them to book their appointments
                online, not only delivering an impressive patient experience but
                also saving your staff time and money compared to handling
                booking requests manually.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="A 24/7 service">
                        View doctor availability
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Patients no longer have to phone your clinic to enquire
                      about a doctor’s availability. With Carebit’s online
                      booking tool, your availability is updated in real-time
                      based on what patients you have booked in and your
                      availability settings in Carebit. Anyone from anywhere in
                      the world can make an appointment to see you: your
                      potential customer base and visibility just increased
                      dramatically.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <a
                        href="https://santis.carebit.co/bookings"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button color="secondary" size="md">
                          View online bookings &rarr;
                        </Button>
                      </a>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={eventsIllustration} />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={creditCardIllustration} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Convenient card payments">
                        Book and pay online whenever it suits
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Both new and existing patients can book and pay for an
                      appointment with just a few clicks. Carebit accepts all
                      major credit and debit cards, and deposits the money in
                      the nominated bank account 7 days later. Secretaries are
                      freed up to focus on other tasks, and the patient saves a
                      good deal of time.
                    </p>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Dominic"
                  authorSubtext="Principal, Carebit"
                  avatarSrc={dominicAvatar}
                >
                  <p>
                    “Private patients are a discerning demographic who want the
                    best service - both in terms of their treatment itself and
                    their customer experience around the treatment. In other
                    sectors, companies invest huge amounts of financial and
                    human resources to provide up-to-date digital experiences
                    across desktop, mobile and tablet as they know how important
                    it is to their customers.
                  </p>
                  <p>
                    Before we set out to build Carebit, we discovered such
                    resources lacking in private healthcare and decided to
                    correct it. As such, Carebit was born out of a need for a
                    modern, patient-friendly, constantly-available platform that
                    delivered a great patient experience - and an experience
                    that reflects positively on you and your practice. Our
                    patient feedback at Santis from using Carebit is that it’s
                    much quicker and more convenient to book and pay online by
                    card - and in fact the majority of our invoices are now
                    settled this way.
                  </p>
                  <p>
                    When your aim is to deliver the best healthcare, why would
                    you scrimp on the patient experience around the care
                    itself?”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
